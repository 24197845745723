<template>
  <v-card :loading="loading">
    <div>
      <div v-if="movingState === moveState.IDLE">
        <table class="mx-3 py-3">
          <tr>
            <td class="title">
              เลขที่ใบนำเข้า
            </td>
            <td>{{ note.code }}</td>
          </tr>
          <tr>
            <td class="title">
              จำนวนสินค้า
            </td>
            <td>{{ total.product }} แบบ</td>
          </tr>
          <tr>
            <td class="title">
              จำนวนชิ้น
            </td>
            <td>{{ total.amount }} ชิ้น</td>
          </tr>
          <tr>
            <td
              class="title"
              colspan="2">
              เลือกสาขาที่ต้องการนำเข้า
            </td>
          </tr>
          <tr>
            <td colspan="2">
              <v-checkbox
                v-model="selectAll"
                label="ทุกสาขา"
                class="checkbox"
                @change="isCheckAll()" />
            </td>
          </tr>
          <tr
            v-for="branch in branches"
            :key="branch.id">
            <td colspan="2">
              <v-checkbox
                v-model="selectedBranches"
                :label="`${branch.name} (${branch.percent}%)`"
                :value="branch.id"
                class="checkbox"
                color="secondary"
                @change="doCheck()" />
            </td>
          </tr>
        </table>
        <v-btn
          class="mx-4 mb-4"
          color="grey lighten-2"
          elevation="0"
          tile
          small
          :disabled="!selectedBranches.length"
          @click="toggleMoveProducts()">
          ย้ายสินค้า
        </v-btn>
      </div>

      <div v-else-if="movingState === moveState.MOVING">
        <separate-products
          :note="note"
          :selected-branches="selectedBranches"
          :warehouse-id="warehouseId"
          :total="total"
          @summary="setupSummaryNotes($event)" />
      </div>

      <div v-else>
        <summary-move-products
          :note-code="note.code"
          :summary-notes="summaryNotes" />
      </div>
    </div>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex'
import WarehouseProvider from '@/resources/WarehouseProvider'
import InventoryProvider from '@/resources/InventoryProvider'
import AutoDeliveryNotesProvider from '@/resources/AutoDeliveryNotesProvider'
import StoreProvider from '@/resources/StoreProvider'
import SeparateProducts from '../components/SeparateProducts.vue'
import SummaryMoveProducts from '../components/SummaryMoveProducts.vue'

const WarehouseService = new WarehouseProvider()
const InventoryService = new InventoryProvider()
const AutoDeliveryNotesService = new AutoDeliveryNotesProvider()
const StoreService = new StoreProvider()

export default {
  components: {
    SeparateProducts,
    SummaryMoveProducts
  },
  data () {
    return {
      selectAll: false,
      selectedBranches: [],
      branches: [],
      loading: false,
      deliveryNoteId: null,
      autoDeliveryNoteId: null,
      warehouseId: null,
      note: {
        code: '-'
      },
      total: {
        product: 0,
        amount: 0
      },
      moveState: {
        IDLE: 'idle',
        MOVING: 'moving',
        SUMMARY: 'summary'
      },
      movingState: 'idle',
      summaryNotes: [],
      summaryNotesStatus: []
    }
  },
  created () {
    this.initAutoDeliveryNote()
    this.initDeliveryNote()
    this.initBranches()
  },
  methods: {
    ...mapActions({
      setSnackbar: 'Components/setSnackbar'
    }),
    toggleMoveProducts () {
      this.selectedBranches = this.branches.filter((branch) => this.selectedBranches.includes(branch.id))

      if (this.selectedBranches.length !== this.branches.length) {
        const totalPercent = this.selectedBranches.reduce((sum, b) => sum + b.percent, 0)
        this.selectedBranches = this.selectedBranches.map((branch) => {
          const tmpBranch = branch
          tmpBranch.percent = tmpBranch.percent !== 0
            ? ((tmpBranch.percent / totalPercent) * 100).toFixed(2) : 0
          return tmpBranch
        })
      }

      this.movingState = this.moveState.MOVING
    },
    async initAutoDeliveryNote () {
      try {
        this.loading = true
        this.note.code = this.$route.query?.noteCode || null
        this.autoDeliveryNoteId = this.$route.query.autoDeliveryNoteId || null
        if (this.autoDeliveryNoteId && this.note.code) {
          this.autoDeliveryNoteId = (+this.autoDeliveryNoteId)
          const { data } = await AutoDeliveryNotesService.getAutoDeliveryNoteById(this.autoDeliveryNoteId)
          this.setupSummaryNotes(data)
        }
      } catch (error) {
        console.error('autoDeliveryNoteById', error)
        this.setSnackbar({
          value: true,
          message: `Error code ${error.code} : ${error.message}`,
          type: 'error'
        })
      } finally {
        this.loading = false
      }
    },
    async initDeliveryNote () {
      try {
        this.loading = true

        this.warehouseId = this.$route.query?.warehouseId || null
        this.deliveryNoteId = this.$route.query?.noteId || null
        if (this.warehouseId && this.deliveryNoteId) {
          this.warehouseId = +this.warehouseId

          const { data } = await InventoryService.getDeliveryNoteById(this.deliveryNoteId)

          const groupProducts = data.products.reduce((obj, p) => {
            const tmpObj = { ...obj }

            if (!(p.id in tmpObj)) {
              tmpObj[p.id] = {
                ...p,
                skus: []
              }
            }

            p.skus.forEach((s) => {
              const skuIndex = tmpObj[p.id].skus.findIndex(
                (i) => i.code === s.code
              )

              if (skuIndex === -1) {
                tmpObj[p.id].skus.push(s)
              } else {
                tmpObj[p.id].skus[skuIndex].amount += s.amount
                tmpObj[p.id].skus[skuIndex].count += s.count
              }
            })

            return tmpObj
          }, {})

          this.note = {
            ...data,
            products: Object.values(groupProducts)
          }

          const amountProducts = this.note.products.length
          let amountSkusMapped = this.note.products.map((product) => {
            const { skus } = product

            return skus.reduce((sum, currentSkus) => sum + currentSkus.amount, 0)
          })

          amountSkusMapped = amountSkusMapped.reduce((sum, currentSkus) => sum + currentSkus, 0)

          this.total = {
            product: amountProducts,
            amount: amountSkusMapped
          }
        }
      } catch (error) {
        console.error('deliveryNoteById', error)
        this.setSnackbar({
          value: true,
          message: `Error code ${error.code} : ${error.message}`,
          type: 'error'
        })
      } finally {
        this.loading = false
      }
    },
    async getMoveStockWarehouse () {
      const { data: { moveStockWarehouse } } = await StoreService.getStoreSetting()
      return moveStockWarehouse
    },
    async initBranches () {
      try {
        this.loading = true

        const availableWarehouses = await this.getMoveStockWarehouse()

        if (this.note) {
          const { data: branches } = await WarehouseService.getWarehouse()

          const availableBranchesId = availableWarehouses.map((b) => b.id)
          this.branches = branches.filter((branch) => availableBranchesId.includes(branch.id) && branch.id !== this.warehouseId)
          this.branches = this.branches.map((branch) => {
            const tmpBranch = branch
            const { percent } = availableWarehouses.find((b) => b.id === branch.id)
            tmpBranch.percent = percent

            return tmpBranch
          })
          this.branches = this.branches.sort((a, b) => b.percent - a.percent)
        }
      } catch (error) {
        console.error(error)
        this.setSnackbar({
          value: true,
          message: `Error code ${error.code} : ${error.message}`,
          type: 'error'
        })
      } finally {
        this.loading = false
      }
    },
    isCheckAll () {
      if (this.selectAll) {
        this.selectedBranches = this.branches.map((branch) => branch.id)
      } else {
        this.selectedBranches = []
      }
    },
    doCheck () {
      if (this.selectAll) {
        this.selectAll = false
      }
    },
    async setupSummaryNotes (notes) {
      this.summaryNotes = await this.updateNotes(notes)
      this.movingState = this.moveState.SUMMARY
    },
    async updateNotes (notes) {
      try {
        const updatedNotes = await Promise.all(
          notes.map(async (note) => ({
            ...note,
            toWarehouses: await Promise.all(
              note.toWarehouses.map(async (w) => {
                const [deliveryStatus, withdrawStatus] = await Promise.all([
                  InventoryService.getDeliveryNoteById(w.deliveryNote.id),
                  InventoryService.getWithdrawNoteById(w.withdrawNote.id)
                ])

                const deliveryNote = {
                  ...w.deliveryNote,
                  status: deliveryStatus.data.status,
                  warehouseId: deliveryStatus.data.warehouse.id
                }
                const withdrawNote = {
                  ...w.withdrawNote,
                  status: withdrawStatus.data.status,
                  warehouseId: withdrawStatus.data.warehouse.id
                }

                return {
                  ...w,
                  deliveryNote,
                  withdrawNote
                }
              })
            )
          }))
        )
        return updatedNotes
      } catch (error) {
        console.error(error)
        this.setSnackbar({
          value: true,
          message: `Error code ${error.code} : ${error.message}`,
          type: 'error'
        })
        return []
      }
    }
  }
}
</script>

<style scoped>
.title {
  font-weight: 600;
}
table {
  min-width: 400px;
}
td {
  padding: 5px 2px;
}
.checkbox {
  margin: 0 !important;
  padding: 0 0 0 10% !important;
}
</style>
